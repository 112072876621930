import React from 'react'
import Header from '../components/common/Header'
import AppCTA from '../components/cta/AppCTA'
import GeneralCTA from '../components/cta/GeneralCTA.js'
import NewsletterCTA from '../components/cta/NewsletterCTA.js'
import Layout from '../components/layout'
import Seo from '../components/seo'
import LatestWebinar from '../components/webinar/LatestWebinar.js'
import WebinarGrid from '../components/webinar/WebinarGrid.js'

class RootIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo
          title="Webinars | Urban Fox | EV Charging"
          description="Explore the future of electric vehicle charging with Urban Fox EV Charging. Register for our upcoming webinars and watch our previous webinars on-demand."
        />
        <Header filter="Webinar" />
        <LatestWebinar />
        <WebinarGrid />
        <NewsletterCTA />
        <AppCTA />
        <GeneralCTA />
      </Layout>
    )
  }
}

export default RootIndex
