import { graphql, useStaticQuery } from 'gatsby'
import get from 'lodash/get'
import React from 'react'
import Icon from '../common/Icon'
import Preview from './Preview'

const WebinarGrid = (props) => {
  const data = useStaticQuery(graphql`
    query WebinarQuery {
      allContentfulWebinar(sort: { fields: [startTime], order: DESC }) {
        nodes {
          title
          url
          youTubeUrl
          startTime(formatString: "MMMM Do, YYYY HH:mm")
          endTime(formatString: "HH:mm")
          promoImage {
            gatsbyImage(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 424
              height: 212
            )
          }
        }
      }
    }
  `)

  const webinars = get(data, 'allContentfulWebinar.nodes', null)

  if (!webinars) {
    return (
      <div className="w-full max-w-7xl mx-auto px-6">
        <h3 className="text-3xl font-bold text-center text-gray-900 py-16">
          No webinars found.
        </h3>
        <Icon
          name="CalendarDaysIcon"
          className="w-full h-24 mb-24 text-uforange-600"
        />
      </div>
    )
  }

  return (
    <div className="relative bg-white px-4 pt-16 pb-20 sm:px-6 lg:px-8 lg:pt-24 lg:pb-28 max-w-7xl mx-auto">
      <h3 className="text-3xl font-bold text-center text-gray-900">
        All our Webinars
      </h3>
      <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
        {webinars.map((webinar) => (
          <Preview webinar={webinar} />
        ))}
      </div>
    </div>
  )
}

export default WebinarGrid
