import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import Button from '../common/Button'

const Preview = ({ webinar }) => {
  const link = webinar.youTubeUrl ? webinar.youTubeUrl : webinar.url
  const label = webinar.youTubeUrl ? 'Watch' : 'Register'
  return (
    <div
      key={webinar.title}
      className="col-span-1 flex flex-col overflow-hidden rounded-lg w-96"
    >
      <p className="font-bold mb-1">{webinar.title}</p>
      <div className="flex-shrink-0 rounded relative">
        <GatsbyImage
          alt={`${webinar.title} hero image`}
          image={webinar.promoImage.gatsbyImage}
          className="h-full w-full object-cover rounded-lg"
        />
        <div className="flex justify-end mt-2 absolute bottom-2 right-2">
          <Button href={link} label={label} />
        </div>
      </div>
    </div>
  )
}

export default Preview
